<template>
  <div style="height: 100%">
    <v-row class="hidden-md-and-up mobile-header no-gutters">
      <v-col cols="6" class="text-center mobile-header-item" :class="transactionObj.transactionType.toLowerCase() == 'deposit' ? 'selected' : ''" :to="{ name: routeName.DEPOSIT }">
        <router-link :to="routeName.DEPOSIT" style="text-decoration: unset !important">
          <span>{{ $t(`label.deposit`) }}</span>
        </router-link>
      </v-col>
      <v-col cols="6" class="text-center mobile-header-item" :class="transactionObj.transactionType.toLowerCase() == 'withdrawal' ? 'selected' : ''" :to="{ name: routeName.WITHDRAWAL }">
        <router-link :to="routeName.WITHDRAWAL" style="text-decoration: unset !important">
          <span>{{ $t(`label.withdrawal`) }}</span>
        </router-link>
      </v-col>
    </v-row>
    <v-card class="" :class="$vuetify.breakpoint.mdAndUp ? 'desktop_withdraw_card' : 'desktop_card'">
      <!--      <v-card-title class="pb-0 hidden-sm-and-down">-->
      <!--        <label-->
      <!--          class="&#45;&#45;v-primary-base text-left text-capitalize mr-4">{{-->
      <!--            $t(`label.${transactionObj.transactionType.toLowerCase()}`)-->
      <!--          }}</label>-->
      <!--      </v-card-title>-->
      <v-card-title class="hidden-sm-and-down pl-3 ml-7">
        <label class="--v-primary-base withdraw-desktop-txt text-left text-capitalize">{{ $t(`label.${transactionObj.transactionType.toLowerCase()}`) }}</label>
      </v-card-title>
      <v-card class="hidden-md-and-up mobile-balance-card">
        <div class="mobile-balance-div">
          <div>
            <span style="color:#FFFFFF">{{ $t(`label.wallet_balance`) }}</span>
            <v-icon small color="white" class="rotating" style="margin-left:5px;" @click="getMemberWallet()">
              mdi-reload
            </v-icon>
          </div>
          <div>
            <span v-if="notLoading" style="color:#FFCE01">{{ memberWallet.totalBalance | currency(transactionObj.currency) }}</span>
            <v-progress-circular indeterminate v-if="!notLoading" color="amber" :width="3" :size="24"></v-progress-circular>
          </div>
        </div>
      </v-card>
      <v-card class="bank-detail-card phoneVerificationAlert" v-if="!this.isPhoneVerified">
        <v-row no-gutters class="section-container" :class="$vuetify.breakpoint.mdAndUp ? '' : 'px-8'">
          <div class="d-flex align-center">
            <v-img src="/static/svg/personal-info-warning.svg" width="25" height="25" :contain="true"></v-img>
            <label class="px-2">{{ $t(`label.phone_not_verify`) }}</label>
            <label class="link-underscore warning-text" @click="this.RedirectToProfilePage">{{ $t(`button.verify`) }}</label>
          </div>
        </v-row>
      </v-card>

      <v-row v-if="false" no-gutters justify="end" class="px-10">
        <v-col class="text-right" cols="12">
          <v-btn
            style="visibility: hidden;"
            v-for="method in availableMethods"
            :key="method.code"
            class="deposit-tab"
            :color="transactionObj.methodCode == method.code ? 'primary' : ''"
            depressed
            @click="changeWithdrawalMethod(method)"
          >
            {{ getAvailableMethodDisplayName(method) }}
          </v-btn>
        </v-col>
      </v-row>
      <v-card class="bank-detail-card deposit-card" elevation="0">
        <v-form ref="transactionForm">
          <!--desktop view withdrwal options-->
          <v-row no-gutters align="end" class="hidden-sm-and-down">
            <v-col md="8">
              <label class="input-field-label ma-0 text-capitalize d-block" :class="$vuetify.breakpoint.mdAndDown ? 'pb-1' : 'pb-2'">
                {{ $t(`field.withdrawalOption`) }}
                <span class="red--text ml-1">*</span>
              </label>
              <v-row no-gutters class="py-2">
                <v-col cols="auto" v-for="gateway in availableMethods" :key="gateway.code">
                  <v-btn
                    :value="gateway.code"
                    :input-value="transactionObj.gatewayAccountBankId == gateway.code"
                    :class="gateway == 0 ? 'pa-2 gateway-button' : $vuetify.breakpoint.xsOnly ? 'ml-1 pa-2 gateway-button' : 'mr-4 pa-2 gateway-button'"
                    active-class="active-gateway-button"
                    @click="changeWithdrawalMethod(gateway)"
                    height="100%"
                    outlined
                    min-width="90px"
                  >
                    <v-row no-gutters style="display: block">
                      <v-col class="pt-1 pb-0">
                        <img v-if="gateway.code == 'NWLB'" :src="`/static/image/icon/local-bank.png`" />
                        <img v-else :src="`/static/svg/deposit-ewallet-${gateway.code.toLowerCase()}.svg`" />
                      </v-col>
                      <v-col v-if="currency == 'INR'" class="black--text pt-0 pb-1" style="font-size: 12px;">
                        {{ $t(`field.localBank`) }}
                      </v-col>
                    </v-row>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <!--desktop view mobile verify-->
          <v-row
            no-gutters
            align="end"
            class="pt-4 hidden-sm-and-down"
            v-if="transactionObj.methodCode == 'NAGAD' || transactionObj.methodCode == 'ROCKET' || transactionObj.methodCode == 'BKASH' || transactionObj.methodCode == 'UPAY'"
          >
            <v-col cols="12">
              <label class="input-field-label ma-0 text-capitalize d-block pb-2">
                {{ $t(`field.phoneNumber`) }}
                <span class="red--text ml-1">*</span>
              </label>
            </v-col>
            <v-col md="6">
              <v-select
                v-model.trim="transactionObj.memberBankAccountNumber"
                item-text="text"
                item-value="value"
                class="withdraw-box"
                outlined
                dense
                :items="memberContacts.length > 0 ? memberContacts : [{ text: $t(`message.noDataAvaliable`), value: null, disabled: true }]"
                :rules="[v => !!v || $t(`fieldErrorMessage.requiredDropDownField`)]"
                @change="this.updateSelectedWalletAccountNumber"
                required
              ></v-select>
            </v-col>
          </v-row>
          <v-row no-gutters align="end" class="mt-6 hidden-sm-and-down" v-if="transactionObj.methodCode == 'NWLB' || transactionObj.methodCode == 'IndiaPayout'">
            <v-col cols="12" md="6">
              <div>
                <app-form-field
                  v-model.trim="transactionObj.memberBankAccountId"
                  :label="$t(`label.yourBankAccountDetails`)"
                  :hint="$t(`fieldHint.memberBank`)"
                  type="select"
                  :rules="validator.dropDownListRules()"
                  :items="memberBankAccounts"
                  :change="this.updateSelectedMemberBankAccount"
                  :displayText="this.memberBankAccountDisplayText"
                  :enabledLabel="true"
                ></app-form-field>
              </div>
              <div class="pb-4" v-if="transactionObj.memberBankAccountId">
                <v-row no-gutters justify="center">
                  <v-card class="bank-account-card" elevation="0">
                    <div class="pa-0 px-2 py-2">
                      <v-card-text class="pa-0 px-2 py-1 navigator_active--text font-weight-bold">{{ $t(`label.bankHolderName`) }}{{ ' : ' }}{{ transactionObj.memberBankAccountName }}</v-card-text>
                      <v-card-text class="pa-0 px-2 py-1 navigator_active--text font-weight-bold">{{ $t(`label.accountNumber`) }}{{ ' : ' }}{{ transactionObj.memberBankAccountNumber }}</v-card-text>
                      <v-card-text class="pa-0 px-2 py-1 navigator_active--text font-weight-bold">{{ $t(`label.bankName`) }}{{ ' : ' }}{{ transactionObj.memberBankName }}</v-card-text>
                      <v-card-text class="pa-0 px-2 py-1 navigator_active--text font-weight-bold" v-if="currency == 'INR'">
                        {{ $t(`label.ifscCode`) }}{{ ' : ' }}{{ transactionObj.ifscCode }}
                      </v-card-text>
                    </div>
                  </v-card>
                </v-row>
              </div>
              <div class="pb-2 text-center" v-if="false">
                <v-btn text :ripple="false" class="text-uppercase font-weight-bold text-center navigator_active--text text-decoration-underline" @click="openNewBankAccountDialog">
                  {{ $t(transactionObj.memberBankAccountId != '' ? `label.changeBankAccount` : `label.addBankAccount`) }}
                </v-btn>
              </div>
            </v-col>
          </v-row>
          <v-row no-gutters align="end" class="breakpoint" :class="$vuetify.breakpoint.mdAndUp ? '' : 'px-8 pt-4'">
            <v-col md="6">
              <app-form-field
                :tooltip="
                  stringFormat(
                    $t(`fieldHint.withdrawalAmount`),
                    selectedMethod != '' ? currencyFormatter(minAmount, transactionObj.currency) : currencyFormatter(800, transactionObj.currency),
                    selectedMethod != '' ? currencyFormatter(maxAmount, transactionObj.currency) : currencyFormatter(30000, transactionObj.currency)
                  )
                "
                :placeholder="
                  stringFormat(
                    $t(`fieldHint.minMax`),
                    selectedMethod != '' ? currencyFormatter(minAmount, transactionObj.currency) : currencyFormatter(800, transactionObj.currency),
                    selectedMethod != '' ? currencyFormatter(maxAmount, transactionObj.currency) : currencyFormatter(30000, transactionObj.currency)
                  )
                "
                :enabledLabel="true"
                class=""
                v-model="transactionObj.amount"
                :change="this.updateMoneyAmount"
                :label="$t(`label.withdrawableAmount`)"
                :rules="validator.withdrawalAmountRules(2, minAmount, maxAmount, transactionObj.currency)"
              ></app-form-field>
            </v-col>
          </v-row>
          <!--mobile view withdrwal options-->
          <v-row no-gutters align="end" class="px-8 hidden-md-and-up">
            <v-col lg="4" md="5">
              <label class="input-field-label ma-0 text-capitalize d-block" :class="$vuetify.breakpoint.mdAndDown ? 'pb-1' : 'pb-2'">
                {{ $t(`field.paymentType`) }}
                <span class="red--text ml-1">*</span>
              </label>
              <v-row no-gutters class="py-2">
                <v-col cols="auto" v-for="gateway in availableMethods" :key="gateway.code">
                  <v-btn
                    :value="gateway.code"
                    :input-value="transactionObj.gatewayAccountBankId == gateway.code"
                    :class="gateway == 0 ? 'pa-2 gateway-button' : $vuetify.breakpoint.xsOnly ? 'ml-1 pa-2 gateway-button' : 'ml-4 pa-2 gateway-button'"
                    active-class="active-gateway-button"
                    @click="changeWithdrawalMethod(gateway)"
                    height="100%"
                    outlined
                    min-width="90px"
                  >
                    <v-row no-gutters style="display: block">
                      <v-col class="pt-1 pb-0">
                        <img v-if="gateway.code == 'NWLB'" :src="`/static/image/icon/local-bank.png`" />
                        <img v-else :src="`/static/svg/deposit-ewallet-${gateway.code.toLowerCase()}.svg`" />
                      </v-col>
                      <v-col v-if="currency == 'INR'" class="black--text pt-0 pb-1" style="font-size: 12px;">
                        Local Bank
                      </v-col>
                    </v-row>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <!--mobile view mobile verify-->
          <v-row
            no-gutters
            align="end"
            class="px-8 pt-4 hidden-md-and-up"
            v-if="transactionObj.methodCode == 'NAGAD' || transactionObj.methodCode == 'ROCKET' || transactionObj.methodCode == 'BKASH' || transactionObj.methodCode == 'UPAY'"
          >
            <!--            <v-col lg="4" md="5" v-if="memberContacts.length <= 1">-->
            <!--              <app-form-field-->
            <!--                :delete-text="changeNumber"-->
            <!--                :prefix="mobilePrefix"-->
            <!--                :isOptional="true"-->
            <!--                :enabledLabel="true"-->
            <!--                v-model.trim="transactionObj.memberBankAccountNumber"-->
            <!--                :label="$t(`field.phoneNumber`)"-->
            <!--                :readonly="true"-->
            <!--                :rules="validator.mobileNumberRules('my')"-->
            <!--              ></app-form-field>-->
            <!--            </v-col>-->
            <v-col lg="4" md="5">
              <v-select
                v-model.trim="transactionObj.memberBankAccountNumber"
                item-text="text"
                item-value="value"
                :items="memberContacts"
                :rules="[v => !!v || $t(`fieldErrorMessage.requiredDropDownField`)]"
                label="Select your phone"
                @change="this.updateSelectedWalletAccountNumber"
                required
              ></v-select>
            </v-col>
          </v-row>
          <v-row no-gutters align="end" class="px-8 mt-6 hidden-md-and-up" v-if="transactionObj.methodCode == 'NWLB' || transactionObj.methodCode == 'IndiaPayout'">
            <v-col cols="12" lg="4" md="5">
              <div>
                <app-form-field
                  v-model.trim="transactionObj.memberBankAccountId"
                  :label="$t(`label.yourBankAccountDetails`)"
                  :hint="$t(`fieldHint.memberBank`)"
                  type="select"
                  :rules="validator.dropDownListRules()"
                  :items="memberBankAccounts"
                  :change="this.updateSelectedMemberBankAccount"
                  :displayText="this.memberBankAccountDisplayText"
                  :enabledLabel="true"
                ></app-form-field>
              </div>
              <div class="pb-4" v-if="transactionObj.memberBankAccountId">
                <v-row no-gutters justify="center">
                  <v-card class="bank-account-card" elevation="0">
                    <div class="pa-0 px-2 py-2">
                      <v-card-text class="pa-0 px-2 py-1 navigator_active--text font-weight-bold">{{ $t(`label.bankHolderName`) }}{{ ' : ' }}{{ transactionObj.memberBankAccountName }}</v-card-text>
                      <v-card-text class="pa-0 px-2 py-1 navigator_active--text font-weight-bold">{{ $t(`label.accountNumber`) }}{{ ' : ' }}{{ transactionObj.memberBankAccountNumber }}</v-card-text>
                      <v-card-text class="pa-0 px-2 py-1 navigator_active--text font-weight-bold">{{ $t(`label.bankName`) }}{{ ' : ' }}{{ transactionObj.memberBankName }}</v-card-text>
                      <v-card-text class="pa-0 px-2 py-1 navigator_active--text font-weight-bold" v-if="currency == 'INR'">
                        {{ $t(`label.ifscCode`) }}{{ ' : ' }}{{ transactionObj.ifscCode }}
                      </v-card-text>
                    </div>
                  </v-card>
                </v-row>
              </div>
              <div class="pb-2 text-center" v-if="false">
                <v-btn text :ripple="false" class="text-uppercase font-weight-bold text-center navigator_active--text text-decoration-underline" @click="openNewBankAccountDialog">
                  {{ $t(transactionObj.memberBankAccountId != '' ? `label.changeBankAccount` : `label.addBankAccount`) }}
                </v-btn>
              </div>
            </v-col>
          </v-row>
          <v-row no-gutters align="end" class="breakpoint" :class="$vuetify.breakpoint.mdAndUp ? 'pb-4' : 'pb-4 px-8'">
            <v-col cols="12" md="6">
              <v-btn
                class="dialog-button theme-button withdraw-width"
                @click="this.submitTransaction"
                :class="$vuetify.breakpoint.mdAndUp ? 'withdraw-btn-desktop' : 'withdraw-btn'"
                :disabled="!this.isPhoneVerified"
              >
                {{ $t(`label.withdrawal`) }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </v-card>

    <app-dialog :dialogShow="newBankAccountDialogShow" :max-width="600" :title="$t(`label.bankAccount`)" :closeAction="this.closeNewBankAccountDialog">
      <app-account
        :bankAccountDetail="bankAccountDetail"
        :showFullBankForm="true"
        :banks="banks"
        :closeNewBankAccountDialog="this.closeNewBankAccountDialog"
        @updateBankAccountObject="updateBankAccountData"
      ></app-account>
    </app-dialog>
    <app-dialog
      :dialogShow="msgDialog.show"
      ref="depositConfirmationDialog"
      :max-width="400"
      :title="$t(`label.withdrawalDetails`)"
      :header_title="$t(`label.withdrawalDetails`)"
      :closeAction="this.onCloseClick"
    >
      <v-row no-gutters justify="center" :class="$vuetify.breakpoint.mdAndDown ? 'px-3' : 'px-5'">
        <v-col cols="6">
          <label class="payment-dialog-title">{{ $t(`field.withdrawalAmount`) }}</label>
        </v-col>
        <v-col cols="6" class="text-right">
          {{ transactionObj.amount }}
        </v-col>
        <v-col cols="6" class="mt-2 mb-6">
          <label class="payment-dialog-title">{{ $t(`field.paymentType`) }}</label>
        </v-col>
        <v-col cols="6" class="text-right mt-2 mb-6">
          {{ gatewayAccountBankName }}
        </v-col>
        <v-col cols="8">
          <v-btn height="auto" @click="submitPaymentTransaction" color="black" depressed class="primary-button theme-button text-capitalize pa-2 font-weight-bold yellow--text">
            {{ $t(`label.confirm`) }}
          </v-btn>
        </v-col>
      </v-row>
    </app-dialog>
    <app-dialog
      :dialogShow="showWithdrawalUnavailableDialog"
      ref="withdrawalUnavailableDialog"
      :max-width="400"
      :title="$t(`label.system`)"
      :header_title="$t(`label.system`)"
      :closeAction="this.routeToDeposit"
    >
      <v-row no-gutters justify="center" :class="$vuetify.breakpoint.mdAndDown ? 'px-3' : 'px-5'">
        <v-col cols="12" class="text-center">
          <p>{{ $t(`message.newWithdrawalMsg`) }}</p>
        </v-col>
        <v-col cols="12" class="text-center">
          <p>
            {{ $t(`message.newWithdrawalMsg1`) }}
          </p>
        </v-col>
        <v-col cols="8">
          <v-btn height="auto" @click="routeToDeposit()" color="black" depressed class="primary-button theme-button text-capitalize pa-2 font-weight-bold yellow--text">
            {{ $t(`label.confirm`) }}
          </v-btn>
        </v-col>
      </v-row>
    </app-dialog>
  </div>
</template>

<script>
import { DevicePlatform, ProcessTransactionResponseType } from '@/constants/enums'
import currencyFormat from '@/filters/currency-format'
import { CACHE_KEY } from '@/constants/constants'
import { ROUTE_NAME } from '@/constants/route.constants'
import format from 'string-format'
import AppAccount from '@/components/bank/account.vue'
import { SHARED, SESSION, API } from '@/constants/constants'
import { formValidator, locale, uiHelper, errorCodeHelper } from '@/util'
import { MEMBER_WALLET, MEMBER_RESTRICT_WITHDRAWAL, MEMBER_RESET_RESTRICT_WITHDRAWAL, MEMBER_CHECK_TOKEN_VALIDITY, MEMBET_CHECK_WITHDRAW_STATUS } from '@/store/member.module'
import {
  PAYMENT_SUBMIT_PAYMENT_TRANSACTION,
  PAYMENT_AVAILABLE_METHOD,
  PAYMENT_RESET_TRANSACTION_RESPONSE_STORE,
  PAYMENT_PROCESS_TRANSACTION,
  PAYMENT_RESET_PAYMENT_PROCESS_TRANSACTION_RESPONSE_STORE,
  PAYMENT_MEMBER_BANK_ACCOUNT
} from '@/store/payment.module'
import { WITHDRAWAL_RESTORE } from '@/store/transfer.module'

export default {
  name: 'withdrawal',
  metaInfo: {
    title: `${locale.getMessage('meta.withdrawal_title')}`,
    titleTemplate: `%s | ${uiHelper.getHost()}`,
    meta: [
      { charset: 'utf-8' },
      { name: 'description', content: `${locale.getMessage('meta.withdrawal_description')}` },
      { name: 'keyword', content: `${locale.getMessage('meta.withdrawal_keyword')}` }
    ]
  },
  components: {
    AppAccount
  },
  data: () => ({
    isWithdrawalActive: true,
    showWithdrawalUnavailableDialog: false,
    minAmount: 800,
    maxAmount: 30000,
    notLoading: true,
    routeName: ROUTE_NAME,
    isPhoneVerified: false,
    currencyFormatter: currencyFormat,
    selectedMethod: '',
    storageGameProviderType: uiHelper.getLocalStorage(`${CACHE_KEY.GAME_PROVIDER_TYPE}_${uiHelper.getPlatform()}_${uiHelper.getCurrency()}`),
    localBankWithdrawalStep: 1,
    stringFormat: format,
    validator: formValidator,
    newBankAccountDialogShow: false,
    transactionObj: {
      /* TRANSACTION INFO */
      transactionType: SHARED.WITHDRAWAL,
      methodCode: '',
      amount: '',
      depositDateTime: '',
      attachmentFileType: '',
      attachment: '',
      ipAddress: '',
      saveNewBank: false,
      /* MEMBER INFO */
      memberCode: localStorage.getItem(SESSION.USERNAME),
      memberRealName: '',
      currency: localStorage.getItem(SESSION.CURRENCY),
      memberBankAccountId: '',
      memberBankAccountName: '',
      memberBankAccountNumber: '',
      memberBankName: '',
      memberBankCode: '',
      memberBankProvince: '',
      memberBankBranch: '',
      memberBankCity: '',
      memberEmail: '',
      memberContactIdorNumber: '',
      ifscCode: '',

      /* MERCHANT or ONLINE GATEWAY INFO */
      merchantBankCode: '',
      gatewayAccountId: '',
      gatewayAccountBankId: '',
      /* OTHER */
      transactionSuccessUrl: '',
      oriCurrency: '',
      oriAmount: '',
      language: uiHelper.getLanguage().indexOf('en') != -1 ? 'en' : uiHelper.getLanguage(),
      platform: uiHelper.getPlatform()
    },
    transactionResponseResult: {
      success: false,
      complete: false,
      code: 0
    },
    bankAccountDetail: {
      memberBankAccountId: '',
      memberBankAccountName: '',
      memberBankAccountNumber: '',
      memberBankName: '',
      memberBankProvince: '',
      memberBankBranch: '',
      memberBankCity: '',
      saveNewBank: false,
      memberCode: localStorage.getItem(SESSION.USERNAME),
      isUpdateBank: false
    },
    mobileNumberPrefixList: [
      {
        text: '+91',
        value: 'INR'
      },
      {
        text: '+880',
        value: 'BDT'
      },
      {
        text: '+977',
        value: 'NPR'
      }
    ],
    submittedTransactionResponse: null,
    msgDialog: {
      show: false,
      title: '',
      msg: ''
    },
    gatewayAccountBankName: '',
    currency: uiHelper.getCurrency()
  }),
  computed: {
    restrictWithdrawal() {
      return this.$store.state.member.restrictWithdrawal.complete
    },
    memberContacts() {
      let contactList = this.$store.state.member.info.mobileList
      // let _2ndMobile = this.$store.state.member.info.secondaryMobile;

      let _contacts = contactList
        .filter(x => x.is_verified && x.is_active)
        .map(mobileNumber => {
          return { text: this.mobilePrefix + ' ' + mobileNumber.value, value: mobileNumber.value }
        })

      return _contacts
    },
    mobilePrefix() {
      let self = this
      let prefix = _.find(this.mobileNumberPrefixList, function(p) {
        return p.value == self.transactionObj.currency
      })
      if (prefix) return prefix.text
      else return ''
    },
    banks() {
      return this.$store.state.payment.banks
    },
    availableMethods() {
      return this.$store.state.payment.availableWithdrawalMethods
    },
    memberInfo() {
      return this.$store.state.member.info
    },
    memberBankAccounts() {
      return this.$store.state.payment.memberBankAccounts
    },
    transactionResponseComplete() {
      return this.$store.state.payment.trasnactionResponse.complete
    },
    memberWallet() {
      return this.$store.state.member.walletBalance
    },
    withdrawStatus() {
      return this.$store.state.member.withdrawStatus
    },
    onlinePaymentSupportedBanks() {
      // return this.$store.state.payment.onlinePaymentSupportedBanks
      return this.selectedMethod.banks
    }
  },
  watch: {
    memberWallet() {
      this.notLoading = true
    },
    memberBankAccounts() {
      this.preSelectMemberBankAccount()
    },
    availableMethods() {
      // let withdrawalActive = this.$store.state.payment.availableWithdrawalMethods.find(method => {
      //   return method.isActive && method.code != 'NWLB'
      // })
      // this.isWithdrawalActive = withdrawalActive != undefined
      // if (!this.isWithdrawalActive) {
      //   this.showWithdrawalUnavailableDialog = true
      // }
      this.changeWithdrawalMethod(this.$store.state.payment.availableWithdrawalMethods[0])
      // Get the current date and time
      const now = new Date()

      // Convert current time to GMT+8
      const gmt8Time = new Date(now.valueOf() + 8 * 60 * 60 * 1000)

      // Extract the hour component
      const hour = gmt8Time.getUTCHours()

      // Check if the current time is between 1am and 10am GMT+8
      if (hour >= 1 && hour < 10) {
        if (this.currency === 'BDT') {
          this.showWithdrawalUnavailableDialog = true
        }
      }
    },
    transactionResponseComplete() {
      let transactionResponse = this.$store.state.payment.trasnactionResponse
      if (transactionResponse.complete) {
        try {
          this.$ga.event(
            'Payment',
            `Withdrawal`,
            `${this.transactionObj.methodCode.toUpperCase()} - ${transactionResponse.success ? 'Success' : 'Fail'}${!transactionResponse.success ? ` [Error : ${transactionResponse.code}]` : ''}`,
            transactionResponse.success ? this.transactionObj.amount : 0
          )
        } catch (err) {
          console.log(err)
        }

        this.transactionComplete(transactionResponse)
      }
    },
    restrictWithdrawal() {
      if (this.$store.state.member.restrictWithdrawal.complete) {
        if (this.$store.state.member.restrictWithdrawal.success) {
          if (this.$store.state.member.restrictWithdrawal.is_restrict) {
            this.popTurnoverMsg(this.$store.state.member.restrictWithdrawal)
          } else {
            this.openConfirmationDialog()
          }
        } else {
          this.openConfirmationDialog()
        }
        this.$store.dispatch(`${MEMBER_RESET_RESTRICT_WITHDRAWAL}`)
      }
    },
    withdrawStatus() {
      let status = this.$store.state.member.withdrawStatus
      this.checkWithdrawStatus(status)
    }
  },
  async created() {
    this.metaTag()
    this.isPhoneVerified = this.IsMemberPhonesVerified()
    this.checkMemberTokenValidity()
    this.openConfirmationDialog()
    //this.getMemberWithdrawStatus()
    this.getPaymentMethod()
    await this.getMemberBankAccount()
    this.checkRestrictWithdrawal()
  },
  beforeMount() {
    this.$eventHub.$once('restrict-withdrawal-checking', () => {
      //this.checkRestrictWithdrawal()
    })
  },
  methods: {
    getMemberBankAccount() {
      let obj = {
        memberCode: localStorage.getItem(SESSION.USERNAME)
      }
      this.$store.dispatch(`${PAYMENT_MEMBER_BANK_ACCOUNT}`, {
        obj
      })
    },
    metaTag() {
      const currentUrl = window.location.href
      const domainWithProtocol = currentUrl.split('/')[2]
      const domain = domainWithProtocol.replace(/(^\w+:|^)\/\//, '')
      const path = window.location.pathname
      const cleanPath = path.replace(/\/(en-BD|bn-BD|en-IN|hi-IN|en-NP|ne-NP)/i, '')
      const Links = `https://${domain}${cleanPath}`
      const Linksen = `https://${domain}/en-BD${cleanPath}`
      const Linksbn = `https://${domain}/bn-BD${cleanPath}`
      const Linksenin = `https://${domain}/en-IN${cleanPath}`
      const Linksenhi = `https://${domain}/hi-IN${cleanPath}`
      const Linksenennp = `https://${domain}/en-NP${cleanPath}`
      const Linksennenp = `https://${domain}/ne-NP${cleanPath}`

      uiHelper.setHreflangLinks(Links)
      uiHelper.setHreflangLinksen(Linksen)
      uiHelper.setHreflangLinksbd(Linksbn)
      uiHelper.setHreflangLinksenin(Linksenin)
      uiHelper.setHreflangLinkshi(Linksenhi)
      uiHelper.setHreflangLinksennp(Linksenennp)
      uiHelper.setHreflangLinksnenp(Linksennenp)
    },
    routeToDeposit() {
      // this.$router.push({
      //   name: ROUTE_NAME.DEPOSIT
      // });
      this.showWithdrawalUnavailableDialog = false
    },
    getMemberWallet() {
      this.notLoading = false
      this.$store.dispatch(MEMBER_WALLET)
    },
    checkRestrictWithdrawal() {
      this.$store.dispatch(MEMBER_RESTRICT_WITHDRAWAL)
    },
    RedirectToProfilePage() {
      this.$router.push({ name: ROUTE_NAME.PERSONAL })
    },
    IsMemberPhonesVerified() {
      let _mobile = this.memberInfo.mobile
      let _2ndMobile = this.memberInfo.secondaryMobile

      if (_mobile.is_verified) {
        return true
      }

      if (_2ndMobile.is_verified) {
        return true
      }
      return false
    },
    changeNumber(e) {
      if (this.transactionObj.memberBankAccountNumber.length <= 10) {
        return e.preventDefault()
      }
    },
    selectPaymentGateway(gateway) {
      this.transactionObj.gatewayAccountBankId = gateway.code
      this.gatewayAccountBankName = gateway.name
    },
    getAvailableMethodDisplayName(method) {
      let self = this
      return method.localization.find(m => m.language == self.transactionObj.language).description
    },
    preSelectMemberBankAccount() {
      if (uiHelper.getCurrency() === 'INR' || uiHelper.getCurrency() === 'NPR') {
        if (this.memberBankAccounts.length > 0) {
          let memberBankAccounts = this.memberBankAccounts.find(x => x.id != '0')
          if (memberBankAccounts != null && memberBankAccounts != undefined) {
            this.transactionObj.memberBankAccountId = memberBankAccounts.id
            this.transactionObj.memberBankName = memberBankAccounts.bankName
            this.transactionObj.memberBankAccountNumber = memberBankAccounts.accountNumber
            this.transactionObj.memberBankAccountName = memberBankAccounts.accountName
            this.transactionObj.merchantBankCode = memberBankAccounts.bankCode
            this.transactionObj.memberBankCode = memberBankAccounts.bankCode
            this.transactionObj.ifscCode = memberBankAccounts.ifscCode == null || memberBankAccounts.ifscCode == undefined ? '' : memberBankAccounts.ifscCode
          } else {
            this.promptAddBankAccount()
          }
        } else {
          this.promptAddBankAccount()
        }
      }
    },
    promptAddBankAccount() {
      let dialog = this.initializePageDialogProperty()
      dialog.title = locale.getMessage(`label.info`)
      dialog.message.push(locale.getMessage(`message.please_add_bank_account`))
      dialog.button.push({
        title: locale.getMessage(`label.ok`),
        action: () => this.redirectToBankAccountPage()
      })
      this.$eventHub.$emit('open-page-dialog', dialog)
    },
    redirectToBankAccountPage() {
      this.closePageDialog()
      this.$router.push({
        name: ROUTE_NAME.BANK_ACCOUNT
      })
    },
    transactionComplete(response) {
      this.$parent.$parent.getMemberWallet()
      this.$store.dispatch(`${PAYMENT_RESET_TRANSACTION_RESPONSE_STORE}`)

      this.transactionResponseResult = {
        success: response.success,
        complete: response.complete,
        code: response.code
      }
      this.processTransactionResponseDialog(response)
    },
    processTransactionResponseDialog(response) {
      if (response.code != 0 && !response.success) {
        // let dialog = this.$parent.$parent.initializePageDialogProperty()
        // dialog.title = locale.getMessage(`label.${response.action}`)
        // dialog.message.push(errorCodeHelper.getErrorCodeDesc(response.code))

        // dialog.button.push({
        //     title: locale.getMessage(`label.close`),
        //     action: this.$parent.$parent.closePageDialog
        // })
        // this.$parent.$parent.openPageDialog(dialog)
        let errorMsg = typeof response.error == 'undefined' || response.error == null || response.error === '' ? errorCodeHelper.getErrorCodeDesc(response.code) : response.error
        this.$parent.openAlert(false, errorMsg)
      } else {
        if (this.transactionObj.methodCode !== 'lb') {
          this.$parent.openAlert(true, locale.getMessage(`message.withdrawalSubmitSuccess`))
        } else {
          let processTransactionResponseType = this.submittedTransactionResponse.processTransactionResponseType.id
          let processTransactionResponseData = response.data.data

          if (processTransactionResponseType == ProcessTransactionResponseType.HTML_AUTO_REDIRECT || processTransactionResponseType == ProcessTransactionResponseType.HTML_REDIRECT) {
            let routeData = this.$router.resolve({
              name: ROUTE_NAME.PROCESS_TRANSACTION,
              query: {
                html: processTransactionResponseData
              }
            })
            window.open(routeData.href, 'Process Transaction', 'width=660,height=550', '_blank')
          } else if (processTransactionResponseType == ProcessTransactionResponseType.URL_LINK || processTransactionResponseType == ProcessTransactionResponseType.IMAGE_URL) {
            if (uiHelper.getPlatform() == DevicePlatform.MOBILE) {
              window.location = processTransactionResponseData
            } else {
              uiHelper.openPopup(processTransactionResponseData, 'Process Transaction', 1200, 900)
            }
          } else if (processTransactionResponseType == ProcessTransactionResponseType.IMAGE_DATA) {
            this.qrCodeUrl = processTransactionResponseData
          }
        }
      }

      this.transactionResponseResult = {
        success: response.success,
        complete: response.complete,
        code: response.code
      }
    },
    updateMoneyAmount(data) {
      this.transactionObj.amount = data.replace(/^0+/, '')
    },
    changeWithdrawalMethod(method) {
      this.selectedMethod = method
      this.transactionObj.methodCode = method.code
      this.transactionObj.gatewayProviderCode = method.gatewayProviderCode[0].gatewayCode
      if (method.code.toLowerCase() == 'lb') {
        this.localBankWithdrawalStep = 1
      } else {
        this.transactionObj.memberBankAccountId = ''
        this.transactionObj.memberBankName = ''
        this.transactionObj.memberBankAccountNumber = this.memberInfo.mobile.value
        this.transactionObj.memberBankAccountName = this.memberInfo.mobile.value
        this.transactionObj.gatewayAccountBankId = method.code
        this.gatewayAccountBankName = method.name
        // this.transactionObj.gatewayAccountBankId = this.onlinePaymentSupportedBanks[0].code
        // this.gatewayAccountBankName = this.onlinePaymentSupportedBanks[0].name
      }
      this.minAmount = this.selectedMethod.minAmount.filter(x => x.gatewayCode === method.gatewayProviderCode[0].gatewayCode)[0].minAmount
      this.maxAmount = this.selectedMethod.maxAmount.filter(x => x.gatewayCode === method.gatewayProviderCode[0].gatewayCode)[0].minAmount
    },
    getMemberWithdrawStatus() {
      this.$store.dispatch(MEMBET_CHECK_WITHDRAW_STATUS)
    },
    proceedToMemberWithdrawalDetailTab() {
      if (this.$refs.withdrawalStepOneForm.validate()) {
        this.localBankWithdrawalStep = 2
      }
    },
    backToPreviousStepDetailTab() {
      this.localBankWithdrawalStep = 1
    },
    updateBankAccountData(bankAccountObj) {
      if (bankAccountObj != null) {
        this.tempMemberBankAccountId = '0'
        this.transactionObj.memberBankAccountId = '0'
        this.transactionObj.memberBankAccountName = bankAccountObj.memberBankAccountName
        this.transactionObj.memberBankAccountNumber = bankAccountObj.memberBankAccountNumber
        this.transactionObj.memberBankName = bankAccountObj.memberBankName
        this.transactionObj.memberBankProvince = bankAccountObj.memberBankProvince
        this.transactionObj.memberBankBranch = bankAccountObj.memberBankBranch
        this.transactionObj.memberBankCity = bankAccountObj.memberBankCity
        this.transactionObj.saveNewBank = bankAccountObj.saveNewBank
      } else {
        this.transactionObj.memberBankAccountId = 0
        this.transactionObj.memberBankAccountName = ''
        this.transactionObj.memberBankAccountNumber = ''
        this.transactionObj.memberBankName = ''
        this.transactionObj.memberBankProvince = ''
        this.transactionObj.memberBankBranch = ''
        this.transactionObj.memberBankCity = ''
        this.transactionObj.saveNewBank = ''
      }

      this.closeNewBankAccountDialog()
    },
    makeNewTransaction() {
      this.localBankWithdrawalStep = 1
      this.transactionObj = {
        /* TRANSACTION INFO */
        transactionType: SHARED.WITHDRAWAL,
        methodCode: '',
        amount: 0,
        depositDateTime: '',
        attachmentFileType: '',
        attachment: '',
        ipAddress: '',
        saveNewBank: false,
        /* MEMBER INFO */
        memberCode: localStorage.getItem(SESSION.USERNAME),
        memberRealName: '',
        currency: localStorage.getItem(SESSION.CURRENCY),
        memberBankAccountId: '',
        memberBankAccountName: '',
        memberBankAccountNumber: '',
        memberBankName: '',
        memberBankProvince: '',
        memberBankBranch: '',
        memberBankCity: '',
        memberEmail: '',
        memberContactIdorNumber: '',

        /* MERCHANT or ONLINE GATEWAY INFO */
        merchantBankCode: '',
        gatewayAccountId: '',
        gatewayAccountBankId: '',
        /* OTHER */
        transactionSuccessUrl: uiHelper.getHostname(),
        oriCurrency: '',
        oriAmount: '',
        language: uiHelper.getLanguage().indexOf('en') != -1 ? 'en' : uiHelper.getLanguage(),
        platform: uiHelper.getPlatform()
      }
      this.submittedTransactionResponse = null
      this.transactionResponseResult = {
        success: false,
        complete: false,
        code: 0
      }
      this.$parent.getMemberBankAccount()
    },
    checkMemberTokenValidity() {
      let requiredLoading = false
      this.$store.dispatch(MEMBER_CHECK_TOKEN_VALIDITY, { requiredLoading })
    },
    getPaymentMethod() {
      let obj = {
        memberCode: localStorage.getItem(SESSION.USERNAME),
        realName: this.memberInfo.name,
        currency: localStorage.getItem(SESSION.CURRENCY)
      }
      this.$store.dispatch(`${PAYMENT_AVAILABLE_METHOD}`, { obj })
    },

    updateSelectedWalletAccountNumber(data) {
      if (data != null) {
        this.transactionObj.memberBankAccountName = data
        this.transactionObj.memberBankAccountNumber = data
      }
    },

    updateSelectedMemberBankAccount(data) {
      if (data != 0) {
        this.tempMemberBankAccountId = data
        let selectedMemberAccount = this.memberBankAccounts.find(x => x.id == data)
        this.transactionObj.memberBankAccountName = selectedMemberAccount.accountName
        this.transactionObj.memberBankAccountNumber = selectedMemberAccount.accountNumber
        this.transactionObj.memberBankName = selectedMemberAccount.bankName
        this.transactionObj.memberBankCode = selectedMemberAccount.bankCode
        this.transactionObj.gatewayAccountBankId = selectedMemberAccount.bankCode
        this.transactionObj.ifscCode = selectedMemberAccount.ifscCode == null || selectedMemberAccount.ifscCode == undefined ? '' : selectedMemberAccount.ifscCode
      } else if (data == 0) {
        this.openNewBankAccountDialog(false)
      }
    },
    submitTransaction() {
      if (this.$refs.transactionForm.validate()) {
        this.msgDialog.show = true
      }
    },
    onCloseClick() {
      this.msgDialog.show = false
    },
    submitPaymentTransaction() {
      let obj = this.transactionObj
      obj.memberRealName = this.memberInfo.name
      this.$store.dispatch(`${PAYMENT_SUBMIT_PAYMENT_TRANSACTION}`, { obj })
      this.onCloseClick()
    },
    openNewBankAccountDialog(otherBank = true) {
      if (this.transactionObj.memberBankAccountId == 0 && otherBank) {
        this.bankAccountDetail = {
          memberBankAccountId: this.transactionObj.memberBankAccountId,
          memberBankAccountName: this.transactionObj.memberBankAccountName,
          memberBankAccountNumber: this.transactionObj.memberBankAccountNumber,
          memberBankName: this.transactionObj.memberBankName,
          memberBankProvince: this.transactionObj.memberBankProvince,
          memberBankBranch: this.transactionObj.memberBankBranch,
          memberBankCity: this.transactionObj.memberBankCity,
          saveNewBank: this.transactionObj.saveNewBank,
          memberCode: this.transactionObj.memberCode,
          isUpdateBank: false
        }
      } else {
        this.bankAccountDetail = null
        this.bankAccountDetail = {
          memberBankAccountName: this.transactionObj.memberBankAccountName
        }
      }

      this.newBankAccountDialogShow = true
    },
    closeNewBankAccountDialog() {
      this.transactionObj.memberBankAccountId = this.tempMemberBankAccountId
      this.newBankAccountDialogShow = false
    },
    memberBankAccountDisplayText(data) {
      if (data.value == 0) {
        return locale.getMessage(`label.${data.bankName}`)
      } else {
        return data.bankName
      }
    },
    popTurnoverMsg(ticket) {
      let errorMessage = [locale.getMessage(`label.withdrawalRestrictedDesc`)]
      let dialog = this.initializePageDialogProperty()
      let turnOverNotMet = ticket.is_restrict

      dialog.dialogXButton = null
      dialog.title = locale.getMessage(`label.withdrawalRestricted`)

      if (!status.turnoverHit) {
        errorMessage.push(`${errorMessage.length}.) ${locale.getMessage(`label.turnoverNotMet`, [ticket.pending_ticket])}`)
        turnOverNotMet = true
      }

      if (errorMessage.length > 1) {
        if (turnOverNotMet) {
          let route = ROUTE_NAME.TURNOVER
          let buttonTitle = locale.getMessage(`label.checkTurnoverStatus`)

          dialog.button.push({
            title: buttonTitle,
            action: () => {
              this.closeWithdrawalRestrictDialog()
              this.$router.push({
                name: route,
                params: {
                  tab: 'RUNNING'
                }
              })
            }
          })
        }
        dialog.message = errorMessage
        this.openPageDialog(dialog)
      }
    },
    checkWithdrawStatus(status) {
      let errorMessage = [locale.getMessage(`label.withdrawalRestrictedDesc`)]
      let dialog = this.$parent.$parent.initializePageDialogProperty()
      let profileNotComplete = false
      let turnOverNotMet = false

      dialog.dialogXButton = null
      dialog.title = locale.getMessage(`label.withdrawalRestricted`)

      if (!status.emailVerified) {
        errorMessage.push(`${errorMessage.length}.) ${locale.getMessage(`label.emailNotVerify`)}`)
        profileNotComplete = true
      }

      if (!status.mobileVerified) {
        errorMessage.push(`${errorMessage.length}.) ${locale.getMessage(`label.mobileNotVerify`)}`)
        profileNotComplete = true
      }

      if (!status.lineVerified) {
        errorMessage.push(`${errorMessage.length}.) ${locale.getMessage(`label.lineNotVerify`)}`)
        profileNotComplete = true
      }

      if (!status.profileComplete) {
        errorMessage.push(`${errorMessage.length}.) ${locale.getMessage(`label.profileIncomplete`)}`)
        profileNotComplete = true
      }

      if (!status.turnoverHit && this.memberWallet.totalBalance > 0) {
        errorMessage.push(`${errorMessage.length}.) ${locale.getMessage(`label.turnoverNotMet`)}`)
        turnOverNotMet = true
      }

      if (errorMessage.length > 1) {
        if (profileNotComplete) {
          dialog.button.push({
            title: locale.getMessage(`label.goToProfilePage`),
            action: () => {
              this.$parent.$parent.closePageDialog()
              this.$router.push({
                name: ROUTE_NAME.PROFILE
              })
            }
          })
        }

        if (turnOverNotMet) {
          let route = ROUTE_NAME.SLOT
          let buttonTitle = locale.getMessage(`label.gotoSlotPage`)

          dialog.button.push({
            title: buttonTitle,
            action: () => {
              this.$parent.$parent.closePageDialog()
              this.$router.push({
                name: route
              })
            }
          })
        }
        dialog.message = errorMessage
        this.$parent.$parent.openPageDialog(dialog)
      }
    },
    viewTransactionHistory() {
      this.$router.push({
        name: ROUTE_NAME.TRANSACTION,
        params: {
          tab: 'withdrawal'
        }
      })
    },
    async restoreWithdrawal() {
      await this.$store.dispatch(`${WITHDRAWAL_RESTORE}`)
      this.$eventHub.$emit('close-page-dialog', true)
      this.preSelectMemberBankAccount()
    },
    openConfirmationDialog() {
      if (this.availableMethods.length > 0) {
        let dialog = this.initializePageDialogProperty()
        dialog.title = locale.getMessage(`label.Confirmation`)
        dialog.message.push(locale.getMessage(`message.withdrawalWarning`))
        dialog.button.push({
          title: locale.getMessage(`label.ok`),
          action: () => this.restoreWithdrawal()
        })
        dialog.button.push({
          title: locale.getMessage(`button.cancel`),
          action: () => this.closePageDialog()
        })
        this.openPageDialog(dialog)
      }
    },
    initializePageDialogProperty() {
      let initPageDialog = {
        title: locale.getMessage(`label.system`),
        dialogXButton: this.closePageDialog,
        messageTitle: '',
        note: '',
        message: [],
        button: []
      }
      return initPageDialog
    },
    openPageDialog(dialog) {
      this.$eventHub.$emit('open-page-dialog', dialog)
    },
    closeWithdrawalRestrictDialog() {
      this.closePageDialog()
    },
    closePageDialog() {
      this.$store.dispatch(`${MEMBER_RESET_RESTRICT_WITHDRAWAL}`)
      this.$eventHub.$emit('close-page-dialog', true)
      this.goToDepositPage()
    },
    goToDepositPage() {
      this.$router.push({
        name: ROUTE_NAME.DEPOSIT
      })
    }
  }
}
</script>
<style lang="scss">
.desktop_withdraw_card {
  border-radius: 12px !important;
  min-height: 700px;
  height: 100%;
  font-family: 'Roboto', sans-serif;
  margin-left: 16px;
  margin-right: 16px;

  .withdraw-desktop-txt {
    font-weight: bold;
  }
}

.rotating {
  transition: transform 1s ease-in-out;
}

.rotating:active {
  transform: rotateZ(360deg);
}

.withdraw-btn-desktop {
  background-color: #0094ff !important;
  height: 40px !important;
  font-family: 'Roboto', sans-serif !important;
  border-radius: 32px !important;
  font-size: 14px;
  color: white !important;
}

.withdraw-btn {
  background-color: var(--v-newMobileSecondary-base) !important;
  color: white !important;
  border-radius: 10px !important;
}

.withdraw-width {
  width: 100%;
}

.withdraw-box {
  font-weight: bold;
}

.mobile-balance-card.v-card {
  background-color: #333333;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  margin-left: 60px;
  margin-right: 60px;
  border-radius: 10px;

  .mobile-balance-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.mobile-header {
  background-color: #000000;
  color: white;
  height: 50px;

  .mobile-header-item {
    display: flex;
    justify-content: center;
    align-items: center;

    &.selected {
      border-bottom: 5px solid yellow;
    }
  }
}

.warning-text {
  color: var(--v-warning-base) !important;
  text-decoration: underline;
  font-size: 14px;
  cursor: pointer;
}

.phoneVerificationAlert {
  display: flex;
  height: 2.5em;
  margin-bottom: 1rem;
  box-shadow: none !important;
}

.deposit-card {
  border-radius: 0px !important;
  overflow-x: hidden;
  overflow-y: hidden;
}

.payment-dialog-title {
  color: #acacac;
}

.card_container {
  margin-top: 70px;
  border-radius: 20px !important;

  &.nonBanking {
    margin-top: 0;
  }
}

.gateway-button {
  width: 60px;

  .v-btn__content {
    img {
      width: 44px;
      height: 44px;
    }
  }
}

.active-gateway-button {
  color: #fff !important;

  &.v-btn--active {
    border: 2px solid #fddd04 !important;
    border-color: #fddd04 !important;
  }
}

@media (max-width: 599px) {
  .card_container {
    margin-top: 20px;
    border-radius: 20px !important;
  }
  .bank-account-card {
    min-width: 250px;
  }
}

@media (min-width: 849px) {
  .mobile-balance-card.v-card {
    margin-bottom: 15px;
    background-color: #333333;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    margin-left: 60px;
    margin-right: 60px;
    border-radius: 10px;

    .mobile-balance-div {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}

@media (max-width: 959px) and (min-width: 849px) {
  .desktop_withdraw_card {
    padding-top: 15px;
    border-radius: 0px !important;
  }
}

@media (max-width: 1008px) {
  .phoneVerificationAlert {
    height: auto;
    margin-top: 1em;
  }
}

@media (max-width: 959px) {
  .breakpoint {
    padding-left: 32px !important;
    padding-right: 32px !important;
  }
}
</style>
